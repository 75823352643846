import * as React from "react";
import { graphql } from "gatsby";
import { Layout, SEO, CJumbotron, CBreadCrumb, LWrap, CHeroImg, CAnchorNav, CCard, CMedia, CBtnList, LPlan } from "../../../components/_index"
import { setLang } from "../../../utils/set-lang"

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter
  const lang = setLang()
  
  return (
    <Layout>
      <SEO title={frontmatter?.title}
        description={frontmatter?.description}
      />
      <CJumbotron
        data={{
          title: {
            en: <>STAY WITH <br className="u_sp"/>PEACE OF MIND</>,
            ja: <>安心してご滞在いただくために <br className="u_sp"/>「これからのステイのたのしみ方」</>
          },
          imgList: [
            {
              img: {
                src: '/assets/images/about/anshin/kv.png'
              },
              imgSp: {
                src: '/assets/images/about/anshin/kv__sp.png'
              }
            }
          ]
        }}
      >
        <CBreadCrumb data={{
          parent: [
            {
              label: 'ホテルの魅力',
              path: '/experience/'
            },
          ],
          current: {
            label: '安心してご滞在いただくために「これからのステイのたのしみ方」'
          }
        }} />
      </CJumbotron>
      <LWrap exClass="u_mbExLarge">
        <section className="u_mbExLarge u_mtExLarge">
          <p className="c_sectLead">仙台ロイヤルパークホテルでは「新しい生活様式」やwithコロナ時代でも安心してご利用いただけるよう、<br className="u_pc"/>感染拡大防止に関する取り組みを実施しております。<br className="u_pc"/>
          素晴らしいリゾートステイをおたのしみいただくため、これからの時代にあわせた最適なおもてなしをお届けしてまいります。</p>
          <CHeroImg img={{
            src: "/assets/images/about/anshin/img_anshin.png",
            alt: ""
          }} />
        </section>
        <section>
          <h2 className="c_headingLv2">新型コロナウイルス対策への <br className="u_sp"/>取り組み</h2>
          <CAnchorNav data={[
            {
              link: {
                href: '#public_space',
              },
              label: "パブリックスペースでの対応"
            },
            {
              link: {
                href: '#staff',
              },
              label: "従業員への対応"
            },
            {
              link: {
                href: '#request',
              },
              label: "お客様へのお願い"
            }
          ]} />

          <h3 className="c_headingLv3 u_tac" id="public_space">パブリックスペースでの対応</h3>
          <CCard exClass="u_mbExLarge" data={[
            {
              img: {
                src: '/assets/images/about/anshin/img_public_space.png',
                alt: "",
              },
              title: "アルコール消毒の設置",
              text: <>客室最上階で滞在をたのしむ <br />プライベートなジュニアスイート</>
            },
            {
              img: {
                src: '/assets/images/about/anshin/img_public_space02.png',
                alt: "",
              },
              title: "アルコール消毒の実施",
              text: <>お客様が触れる機会の多い箇所のアルコール消毒を強化し、安心してホテルをご利用いただけるよう環境整備に努めております。また、婚礼および宴会の際にご利用いただくマイクにはカバーを取り付け、定期的に消毒およびカバーを交換いたします。</>
            },
            {
              img: {
                src: '/assets/images/about/anshin/img_public_space03.png',
                alt: "",
              },
              title: "サービススタッフのマスク等の着用",
              text: <>全てのサービススタッフはマスクを着用しております。また施設により手袋、フェイスシールド、防護眼鏡を着用いたします。</>
            },
            {
              img: {
                src: '/assets/images/about/anshin/img_public_space04.png',
                alt: "",
              },
              title: "アクリルパーテーションの設置",
              text: <>お客様と対面でサービスを行う場所につきましては、飛沫感染防止としてアクリルパーテーションを設置しております。また、婚礼および宴会につきましては、司会台・受付台にアクリルパーティションを設置いたします。</>
            },
            {
              img: {
                src: '/assets/images/about/anshin/img_public_space05.png',
                alt: "",
              },
              title: "フィジカルディスタンスの確保",
              text: <>レストランでは対面での着席を避け、座席の距離を確保いたします。また宴会場では収容人数の制限を行っております。</>
            },
            {
              img: {
                src: '/assets/images/about/anshin/img_public_space06.png',
                alt: "",
              },
              title: "館内換気の強化",
              text: <>館内の換気の強化を図るため、パブリックスペースでは定期的にドアや窓を開放することがございますので、ご了承ください。また客室は全て開閉可能な窓があり、ガーデンサイドの客室にはバルコニーがございます。ご自由に換気・開閉していただき、爽やかな空気を取り入れてください。</>
            },
            {
              img: {
                src: '/assets/images/about/anshin/img_public_space07.png',
                alt: "",
              },
              title: "婚礼および宴会における「3つの密」回避",
              text: <>エレベーター利用の必要がない環境やガーデンを活かしながら、スタッフが適切なアテンドを行い「3つの密」を回避いたします。また、会場では換気や窓の開放を行うとともに、各テーブルでのフィジカルディスタンスも確保いたします。</>
            },
            {
              img: {
                src: '/assets/images/about/anshin/img_public_space08.png',
                alt: "",
              },
              title: "婚礼受付時の感染防止対策",
              text: <>受付台に抗菌おしぼりをご用意しております。ご披露宴受付時の混雑を避けるため、ゲストカードのご利用をお勧めしております。</>
            },
          ]} />

          <h3 className="c_headingLv3 u_tac" id="staff">従業員への対応</h3>
          <CCard align="center" exClass="u_mbExLarge" data={[
            {
              img: {
                src: '/assets/images/about/anshin/img_staff.png',
                alt: "",
              },
              title: "従業員の体調管理",
              text: <>スタッフの出勤時の体調チェック（検温と記録）、手指の消毒を徹底しています。また発熱や体調のすぐれない従業員は自宅待機としております。</>
            },
            {
              img: {
                src: '/assets/images/about/anshin/img_staff02.png',
                alt: "",
              },
              title: "バックスペースにおける消毒・手洗いの徹底",
              text: <>事務所や休憩室、化粧室などバックスペース各所にアルコール消毒液を増設し、入室時や使用後のアルコール消毒や手洗いを徹底しております。</>
            },
          ]} />

          <h3 className="c_headingLv3 u_tac" id="request">お客様へのお願い</h3>
          <CMedia data={[
            {
              img: {
                src: '/assets/images/about/anshin/img_request.png',
                alt: ""
              },
              title: "",
              text: <>
              お客様が安心してホテルをご利用いただけるよう、ご来館時のマスク着用とアルコール消毒への協力をお願いしております。 チェックイン時の検温及びご本人確認、レストランご入店時の検温を実施しておりますのでご協力ください。 またご宿泊のお客様へ健康チェックシートと抗菌おしぼりを配布しております。
              <ul className="c_noteList">
                <li>なお、上記については今後、行政機関等の占める方針・基準に鑑み、更新してまいります。お客様のご利用、ご協力のほどよろしくお願い申しあげます。</li>
              </ul>
              </>,
            }
          ]} />
        </section>
      </LWrap>

      <section className="l_sect u_bgGray">
        <LWrap>
          <h2 className="c_headingLv2">密にならない開放感溢れる<br className="u_sp"/>リゾートステイ</h2>
          <p className="c_sectLead">仙台ロイヤルパークホテルには、広々とした美しいガーデンをはじめ、 テラス席を備えた客室やレストランなど、3つの密（密閉・密集・密接）をつくらないオープンエアの開放的な環境が整っています。 気分も晴れる仙台リゾートステイをおたのしみください。</p>
          <CHeroImg img={{
            src: "/assets/images/about/anshin/img_resort_stay.png",
            alt: ""
          }} />
          <h3 className="c_headingLv3">ゆったり時間をたのしむ「ホテルステイ」</h3>
          <p className="c_sectLead u_tal">緑豊かなガーデンや時間と共に緑豊かな美しい景色を楽しめるテラス席のある客室や足を伸ばして寛げる和室など様々な客室をご用意しております。お好みの客室で素敵な時間をお過ごしください。
            <ul className="c_noteList">
              <li>12タイプのお部屋があります。写真はテラスジュニアスイートツイン。</li>
            </ul>
          </p>
          <CBtnList data={[
            {
              label: '客室一覧',
              link: {
                href: '/stay/guestroom/',
              },
              color: "borderBlack"
            },
            {
              label: '館内施設・サービス',
              link: {
                href: '/stay/facilities/',
              },
              color: "borderBlack"
            }
          ]} />
        </LWrap>
      </section>

      <section className="l_sect04">
        <LWrap>
          <CCard col={2} exClass="u_mbExLarge" data={[
              {
                img: {
                  src: '/assets/images/about/anshin/img_hotelstay.png',
                  alt: "",
                },
                title: "ゆったり時間をたのしむ「ホテルステイ」",
                text: <>緑豊かなガーデンや時間と共に緑豊かな美しい景色を楽しめるテラス席のある客室や足を伸ばして寛げる和室など様々な客室をご用意しております。お好みの客室で素敵な時間をお過ごしください。</>
              },
              {
                img: {
                  src: '/assets/images/about/anshin/img_chefsterrace.png',
                  alt: "",
                },
                title: "優雅に景色と料理をたのしむ「シェフズ テラス」",
                text: <>ガーデンサイドでたのしめる東北・宮城の食材を使ったバラエティ豊かなメニューをご用意しております。ガーデンに降り注ぐ日差しと心地良い風を感じながら美味しいお料理をご堪能ください。</>
              },
              {
                img: {
                  src: '/assets/images/about/anshin/img_picnic.png',
                  alt: "",
                },
                title: "好きな場所で食事と会話をたのしむ「イートピクニック」",
                text: <>ご宿泊のお客様は開放的なガーデンや寛げる自室などお好きな場所で朝食やランチをおたのしみいただけます。安心できる環境でごゆっくり非日常のひと時をお過ごしください。</>
              },
              {
                img: {
                  src: '/assets/images/about/anshin/img_yoga.png',
                  alt: "",
                },
                title: "心身の疲れを癒やす「ガーデンヨガ」",
                text: <>ガーデンでは健康的に美しくなるヨガレッスンをおたのしみいただけます。専属のインストラクターが丁寧にレクチャーいたしますので初心者の方もご安心ください。爽やかな風がそよぐ開放的な環境でのヨガをぜひご体験ください。</>
              },
            ]} />
        </LWrap>
      </section>
      <LPlan />
    </Layout>
  )
}

export default SubPage;

export const query = graphql`
  query ($slug: String!){
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`